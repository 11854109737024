<script>

import {provide, ref} from "vue";

export default {
  props: {
    reference: {
      type: String,
      required: false,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined
    }
  },
  setup(props) {
    let pSubmitted = ref(props.submitted !== undefined ? props.submitted : false);
    provide("pSubmitted", pSubmitted);

    let pDisabled = ref(props.disabled !== undefined ? props.disabled : false);
    provide("pDisabled", pDisabled);
    return {pSubmitted, pDisabled}

  },
  methods: {
   handleKeydownEnter(evt) {
     let name = evt.target.nodeName.toLowerCase();
     if(evt.keyCode === 13 && name!=='textarea') evt.preventDefault();
   }
  },
  watch: {
    submitted(newType) {
      this.pSubmitted = newType;
    },
    disabled(newType) {
      this.pSubmitted = newType;
    },
  }
}
</script>

<template>
  <form :ref="reference" @submit.prevent="" @keydown.enter.stop="handleKeydownEnter($event)">
    <slot/>
  </form>
</template>