import Api from "@/services/api";
import {removeEmptyParams, downloadData} from "@utils";

export default {
    findSyntheseByYear(year) {
        return Api().get("/bilancondtravail/synthese/" + year).then(res => {
            return res.data;
        });
    },
    findSyntheseByCurrentYear() {
        return Api().get("/bilancondtravail/synthese").then(res => {
            return res.data;
        });
    },
    findAll(filter) {
        return Api().get("/bilancondtravails", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findAllYears() {
        return Api().get("/bilancondtravails/years/").then(res => res.data);
    },
    findAllCurrent(filter) {
        return Api().get("/bilancondtravails/current", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findAllByYear(year) {
        return Api().get("/bilancondtravails/year/" + year).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findById(id) {
        return Api().get("/bilancondtravail/" + id).then(res => this.formatRes(res.data));
    },
    findCurrentYear() {
        return Api().get("/bilancondtravail/currentyear").then(res => res.data);
    },
    create(bilanCondTravail) {
        return Api().post("/bilancondtravail", bilanCondTravail).then(res => this.formatRes(res.data));
    },
    update(bilanCondTravail) {
        return Api().put("/bilancondtravail/" + bilanCondTravail.id, bilanCondTravail).then(res => this.formatRes(res.data));
    },
    delete(bilanCondTravail) {
        return Api().delete("/bilancondtravail/" + bilanCondTravail.id).then(() => bilanCondTravail);
    },
    formatRes(e) {
        return e;
    },
    download(year) {
        return Api().get("/bilancondtravail/download/" + year, {responseType: "blob"}).then(res => downloadData(res));
    }
}