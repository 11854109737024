<script>
import useVuelidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'

/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkFormSubmitted from "@components/general/AkFormSubmitted";
import AkInputText from "@components/input/AkInputText";
import AkInputNumber from "@components/input/AkInputNumber";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import bilanCondTravailService from "@services/bilanCondTravailService";
import rolePermissionService from "@services/rolePermissionService";

export default {
  components: {AkFormView, AkFormSubmitted, AkInputText, AkInputNumber},
  mixins: [randomRef, roleMixin],
  setup() {
    return {v$: useVuelidate()}
  },
  metaInfo() {
    return {
      title: "bilan.update",
    }
  },
  data() {
    return {
      submitted: false,
      current: {},
    }
  },
  validations() {
    return {
      current: {
        weekStart: {required}
      }
    }
  },
  mounted() {
    this.getRef().showTotalLoader();

    let p1 = bilanCondTravailService.findById(this.$route.params.id);
    p1.then(data => this.current = data);
  
    let p2 = rolePermissionService.authorizeAccessByPermission(this.permissionConst.healthcareReview.edit);

    Promise.all([p1, p2]).then(()=>{
      this.initData();
      this.getRef().hideLoader();
    });
  },
  methods: {
    initData() {
      if (this.current.weekStart === this.current.weekEnd) this.current.weekEnd = null;
    },
    update() {
      if (!this.checkForm()) {
        this.getRef().error(this.$t("bilan.error.update"));
        return;
      }
      
      if (!this.current.weekEnd) {
        this.current.weekEnd = this.current.weekStart;
      } else if (this.current.weekEnd === this.current.weekStart) {
        this.getRef().error(this.$t("bilan.error.weeks"));
        return;
      }
      
      this.getRef().showLoader(this.$refs.form)
      bilanCondTravailService.update(this.current).then(data => {
        let msg = this.$t("bilan.updated");
        this.$router.replace({ path: `/bilan/condtravail/${data.id}/details`, query: { msg: msg } });
      }).catch(e=>{
        this.getRef().hideLoader();
        this.getRef().error(this.$t("error."+e.response.data.error));
        this.submitted = false;
      });
    },
    checkForm() {
      this.getRef().resetMessages();
      this.submitted = true;
      this.v$.$touch();
      return !this.v$.$error;
    }
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormView :ref="ref" :title="$t('bilan.update')">
    <template v-slot:form>
      <AkFormSubmitted :submitted=submitted reference="form">
        <div class="form-row">
          <AkInputNumber
            :min=1
            :max=53
            v-model="current.weekStart"
            :validator="v$.current.weekStart"
            :label="$t(current.weekEnd ? 'bilan_cond_travail.week_start' : 'bilan_cond_travail.week')"
            class-name="col-md-3"/>
          <AkInputNumber
            :min=1
            :max=53
            v-model="current.weekEnd"
            :label="$t('bilan_cond_travail.week_end')"
            class-name="col-md-3"/>
        </div>
        <div class="form-row">
          <AkInputText :disabled=true v-model=current.agerLabel class-name="col-md-4" :label="$t('ager_label')"/>
          <AkInputText :disabled=true v-model=current.uerLabel class-name="col-md-4" :label="$t('uer_label')"/>
          <AkInputText :disabled=true v-model=current.ceiLabel class-name="col-md-4" :label="$t('cei_label')"/>
        </div>

        <h5>{{ $t('bilan_cond_travail.title1') }}</h5>

        <div class="form-row">
          <AkInputText
              placeholder=" "
              v-model="current.tauxRetourAstreinte"
              :validator="v$.current.tauxRetourAstreinte" 
              :label="$t('bilan_cond_travail.taux_retour_astreinte')"
              class-name="col-md-12"/>
        </div>

        <h5>{{ $t('bilan_cond_travail.title2') }}</h5>
        
        <div class="form-row">
          <AkInputText
              placeholder=" "
              v-model="current.nb1"
              :validator="v$.current.nb1" 
              :label="$t('bilan_cond_travail.nb1')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.nb2"
              :validator="v$.current.nb2" 
              :label="$t('bilan_cond_travail.nb2')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.nb3"
              :validator="v$.current.nb3" 
              :label="$t('bilan_cond_travail.nb3')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.nb4"
              :validator="v$.current.nb4" 
              :label="$t('bilan_cond_travail.nb4')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.nb5"
              :validator="v$.current.nb5" 
              :label="$t('bilan_cond_travail.nb5')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.nb6"
              :validator="v$.current.nb6" 
              :label="$t('bilan_cond_travail.nb6')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.nb7"
              :validator="v$.current.nb7" 
              :label="$t('bilan_cond_travail.nb7')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.nb8"
              :validator="v$.current.nb8" 
              :label="$t('bilan_cond_travail.nb8')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.nb9"
              :validator="v$.current.nb9" 
              :label="$t('bilan_cond_travail.nb9')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.nb10"
              :validator="v$.current.nb10" 
              :label="$t('bilan_cond_travail.nb10')"
              class-name="col-md-12"/>
        </div>

        <h5>{{ $t('bilan_cond_travail.title3') }}</h5>
        
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.nb11"
              :validator="v$.current.nb11" 
              :label="$t('bilan_cond_travail.nb11')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.meoActionRenforcee"
              :validator="v$.current.meoActionRenforcee" 
              :label="$t('bilan_cond_travail.meo_action_renforcee')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.meoOrgTravailSpec"
              :validator="v$.current.meoOrgTravailSpec" 
              :label="$t('bilan_cond_travail.meo_org_travail_spec')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.mepProgActivite"
              :validator="v$.current.mepProgActivite" 
              :label="$t('bilan_cond_travail.mep_prog_activite')"
              class-name="col-md-12"/>
        </div>
        <div class="form-row">
          <AkInputNumber
              placeholder=" "
              v-model="current.orgParticuliereAvWe"
              :validator="v$.current.orgParticuliereAvWe" 
              :label="$t('bilan_cond_travail.org_particuliere_av_we')"
              class-name="col-md-12"/>
        </div>
        
        <div class="float-right">
          <button v-if=this.canEditHealthcare() @click=update() class="btn btn-primary" style="margin-left: 10px">{{ $t('update') }}</button>
        </div>

      </AkFormSubmitted>
    </template>
  </AkFormView>
</template>